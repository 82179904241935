@import '../../theme/palette.sass';
@import '../../theme/variables.sass';

.header-car-result {
  height: 350px !important;
  position: relative;
}
.wrap-input-result {
  bottom: -60px !important;
}

.container_search_floor {
  display: flex;
  justify-content: space-around;
  align-items: center;
  background: #e1eada;
  border-radius: 20px 20px 0px 0px;
  height: 135px;
  position: fixed;
  bottom: 0px;
  width: 100vw;
  .col_search_floor {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    line-height: 19px;
    color: #000000;
  }
  .size_col_search_floor {
    width: 50%;
  }
  .button_search_floor {
    color: #fff;
    background: #39ac00;
    border-radius: 20px;
    padding: 18px 20px;
    border: none;
    width: 260px;
    font-size: 20px;
  }
}

.short {
  margin-top: 20vh;
}

.result-search {
  margin-top: 130px !important;

  .title-result {
    margin-left: 20px !important;
    margin-right: 20px !important;
  }

  span,
  h1 {
    margin-bottom: 30px !important;
    font-size: 40px !important;
    line-height: 33px !important;
  }
  p {
    width: 70% !important;
    margin-top: 20px !important;
    text-align: center !important;
  }
  .img-no-result {
    width: 40% !important;
    margin-top: 80px !important;
    margin-bottom: 0px !important;
  }
  .img-not-connection {
    margin-top: 50px !important;
    margin-bottom: 0px !important;
  }
  .contenedor-normal {
    margin-top: 10px !important;
    h2 {
      font-size: 20px !important;
      line-height: 22px !important;
      text-align: center !important;
    }
    button {
      padding: 12px 10px !important;
      margin-top: 20px !important;
      span {
        margin-top: 0px !important;
        font-size: 16px !important;
        line-height: 20px !important;
      }
    }
  }
}

.resultado-imagenes {
  padding: 0px 1% !important;
  button {
    margin: 23px 30px 15px 30px !important;
    border-radius: 18px !important;
    padding: 8px 8px 34px 8px !important;
    height: 330px !important;
    span {
      margin-top: 0px !important;
      img {
        width: 99% !important;
        height: 250px !important;
        border-radius: 15px;
      }
    }
  }
}

.btn-estacionamiento {
  background-color: $color-secondary !important;
  width: 410px;
  border-radius: 55px !important;
  margin-top: 40px;
  height: 59px;
}

.btn-estacionamiento span {
  color: white;
}

.btn-imagen-auto {
  background-color: $color-primary;
  padding: 5px;
  font-size: 20px;
  font-weight: bold;
  text-transform: uppercase;
  text-align: center;
  border-radius: 20px;
  width: 200px !important;
  border: solid 2px $color-white;
  position: absolute;
  bottom: 10px;
  left: 35%;
  margin-left: -40px;
  color: $color-white;
}

.div-spacer {
  height: 90px;
}
.div-ver-pisos {
  background-color: #e3e9da;
  height: 250px;

  .result-footer {
    position: relative !important;
    bottom: 100px !important;

    .footer-content {
      margin-top: 50px !important;
      margin-left: 0px !important;
      bottom: 0px !important;
      width: 100% !important;
      height: 90px !important;
      @media screen and (min-width: $media-iphone-8-i) and (max-width: $media-iphone-8) {
        bottom: 30px !important;
      }
      @media screen and (min-width: $media-iphone-11-i) and (max-width: $media-iphone-11) {
        bottom: 30px !important;
      }
      .text-not-found {
        font-size: 21px !important;
        line-height: 25px !important;
        font-weight: bold !important;
        padding: 10px 25px !important;
        margin-right: 110px !important;
        @media screen and (min-width: $media-iphone-8-i) and (max-width: $media-iphone-8) {
          padding: 30px 50px !important;
          margin-right: 100px !important;
        }
        @media screen and (min-width: $media-iphone-11-i) and (max-width: $media-iphone-11) {
          padding: 27px 50px !important;
          margin-right: 100px !important;
        }
      }
      .btn-ver-pisos {
        height: 40px !important;
        margin-top: 0px !important;
        border-radius: 20px !important;
        @media screen and (max-width: $media-iphone-se) {
          margin-top: 5px !important;
        }
        @media screen and (min-width: $media-iphone-8-i) and (max-width: $media-iphone-8) {
          margin-top: -5px !important;
        }
        @media screen and (min-width: $media-iphone-11-i) and (max-width: $media-iphone-11) {
          height: 48px !important;
          border-radius: 100px !important;
        }
        span {
          @media screen and (max-width: $media-iphone-se) {
            font-size: 16px !important;
            line-height: 21px !important;
          }
          @media screen and (min-width: $media-iphone-8-i) and (max-width: $media-iphone-11) {
            font-size: 21px !important;
            line-height: 25px !important;
          }
        }
      }
    }
    .img-man {
      bottom: 15px !important;
      right: 22px !important;
      @media screen and (min-width: $media-iphone-8-i) and (max-width: $media-iphone-8) {
        right: 27px !important;
      }
      @media screen and (min-width: $media-iphone-11-i) and (max-width: $media-iphone-11) {
        right: 30px !important;
      }
    }
  }
}

.container-images-result-search-cars {
  display: flex;
  justify-content: center;
  width: 100%;
}

.modal-result-search {
  border-radius: 20px !important;
  margin: 30% auto !important;
  // min-height: 500px;

  padding: 10% 3% !important;
  .icon-camara {
    width: 101px !important;
    height: 82px !important;
  }
  h1 {
    font-size: 40px !important;
  }
  .text-modal {
    font-size: 30px !important;
    line-height: 24px !important;
  }
  .btn-modal {
    width: 80% !important;
    border-radius: 20px !important;
    margin-top: 50px !important;
    height: 64px !important;
    font-size: 20px !important;
  }
}
