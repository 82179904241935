@import "../../theme/palette.sass";
@import "../../theme/variables.sass";

.inactivity-modal {
  width: 800px !important;
  border-radius: 48px !important;

  .modal-icon {
    width: 234px;
    height: 234px;

    path {
      fill: #4F4F4F;
    }
  }

  .modal-description {
    font-style: normal;
    font-weight: normal;
    font-size: 48px;
    line-height: 56px;
    text-align: center;
    color: #4F4F4F;
    padding: 0px 90px;
    margin-top: 30px;
  }

  .modal-title {
    font-style: normal;
    font-weight: bold;
    font-size: 64px;
    line-height: 75px;
    text-align: center;
    color: #4F4F4F;
    margin: 26px auto;
  }

  .modal-btn-no {
    background-color: white;
    border-radius: 48px;
    border: 2px solid #939393;
    width: 85% !important;
    color: #939393;
    padding: 23px 36px !important;
    font-style: normal;
    font-weight: bold;
    font-size: 36px;
    line-height: 42px;
    align-items: center;
    text-align: center;
    text-transform: none;
  }

  .modal-btn-yes {
    background-color: #0251B6;
    border: 2px solid #0251B6;
    border-radius: 48px;
    width: 85% !important;
    color: white;
    padding: 23px 36px !important;
    font-style: normal;
    font-weight: bold;
    font-size: 36px;
    line-height: 42px;
    align-items: center;
    text-align: center;
    text-transform: none;
    margin-top: 20px;
    margin-bottom: 30px;
  }

  .modal-btn-yes:hover {
    background-color: #0250b6e1;
  }

  @media screen and (orientation: landscape) {
    margin: 12% auto !important;
    width: 550px !important;
    padding: 55px 20px !important;

    img {
      width: 150px !important;
      height: 150px !important;
    }

    .modal-description {
      font-size: 30px;
      line-height: 38px;
      padding: 0px 80px;
    }

    .modal-title {
      font-size: 46px;
      line-height: 56px;
      margin: 16px auto;
    }

    .modal-btn-yes {
      font-size: 24px;
      line-height: 30px;
      margin-bottom: 20px;
      padding: 13px 26px !important;
      width: 83% !important;
    }

    .modal-btn-no {
      font-size: 24px;
      line-height: 30px;
      padding: 13px 26px !important;
      width: 83% !important;
    }
  }
}