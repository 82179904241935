@import '../../theme/palette.sass';
@import '../../theme/variables.sass';

.wrap-lista {
  background-color: white;
  min-height: 1420px;
  margin-top: 500px;
  position: relative;

  @media screen and (orientation: landscape) {
    margin-top: 250px;
  }
}

.wrap-lista-body .wrap-lista {
  padding-top: 40px;

  @media screen and (orientation: landscape) {
    padding-top: 18px;
  }
}

.wrap-list-fetching {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: white;
}

.loading-map-wrapper {
  z-index: 2;
}

ul,
li {
  list-style: none;
}

ul {
  margin-block-start: 0px;
}

.wrap-lista .store-list .item-store {
  align-items: center;
  border-bottom: solid 1px #eeeeee;
  display: flex;
  overflow: hidden;
  padding: 10px 0px;
}

.wrap-lista .store-list .item-category {
  align-items: center;
  border-bottom: solid 1px #eeeeee;
  display: flex;
  overflow: hidden;
  padding: 10px 0px;

  .icon-tienda {
    float: left;
    overflow: hidden;
    width: 150px;
  }
}

.logo-tienda {
  width: 100%;
  height: 100%;
  float: left;
  text-align: left;
  overflow: hidden;
  color: white;

  @media screen and (min-width: $media-desktop) {
    .img-logo {
      max-width: 150px;
      max-height: 55px;
    }
  }
}

.logo-tienda img {
  @media screen and (min-width: $media-desktop) {
    height: 100%;
  }
}

.nombre-tienda{
  font-size: 30px;
  padding: 11px;
  float: left;
  margin-left: 15px;
  text-transform: capitalize !important;
}

.category-name{
  font-size: 30px;
  padding: 11px;
  float: left;
  text-align: left;
  margin-left: 15px;
  text-transform: capitalize !important;
}

.route-disabled {
  color: #B1B1B1;
}

.informacion-adicional {
  margin-top: 8px;
  display: flex;
  flex-direction: row;
  align-items: center;

  @media screen and (orientation: landscape) {
    float: right;
  }

  @media (min-width: $media-desktop) and (orientation: portrait) {
    float: right;
  }
}

.wrap-lista .informacion-adicional{
  margin-left: auto;
}

.label {
  border: solid 1px #ccc;
  border-radius: 30px;
  float: left;
  font-size: 16px;
  padding: 5px 8px;
  margin: 5px;
  white-space: nowrap;
}

.label-piso{
  border: solid 1px #646464;
}

.fill-red {
  background-color: $color-red;
  color: white;
  border: solid 1px $color-red;
}

.text-hours {
  color: $color-black;
}

.open-or-open-soon {
  color: $color-black;
  background-color: $color-white;
  border: solid 1px #646464;
}

.scroll-spy {
  position: fixed;
  right: 10px;
  margin-top: 35px;
}

.scroll-spy li {
  padding: 5px;
  text-align: center;
}

.scroll-spy .alphabetic-list li {
  color: #4F4F4F;
}

.tiendas .scroll-spy li {
  .is-active {
    background-color: #0251b7 !important;
    color: $color-white;
  }
}

.gastronomia .scroll-spy li {
  .is-active {
    background-color: #ff0800 !important;
    color: $color-white;
  }
}

.servicios .scroll-spy li {
  .is-active {
    background-color: #707070 !important;
    color: $color-white;
  }
}

.wrap-lista .scroll-spy li{
  padding: 5px;
  border: none;
}

.wrap-lista.gastronomia .scroll-spy li a {
  color: $color-red;
}

.wrap-lista.gastronomia .scroll-spy li a.is-active {
  background-color: $color-red;
  color: white;
  padding-top: 3px;
}

.wrap-lista.servicios .scroll-spy li a {
  color: $color-black;
}

.wrap-lista.servicios .scroll-spy li a.is-active {
  background-color: $color-black;
  color: white;
}

.scroll-spy li a {
  border-radius: 50%;
  background-color: white;
  width: 20px;
  height: 20px;
  display: block;
  color: $color-blue;
  font-size: 14px;
}

.scroll-spy li a.is-active {
  border-radius: 50%;
  background-color: $color-blue;
  width: 20px;
  height: 20px;
  display: block;
  color: white;
  font-size: 14px;
  padding-top: 3px;
}

.wrap-lista .demoIcon{
  color: $color-gray-dark;
  line-height: initial;
  margin: 0;
  width: 150px;
}
.wrap-lista .demoIcon i{
  font-size: 65px;
}

@media (orientation: portrait) {
  .scroll-spy li a {
    width: 30px;
    height: 30px;
    font-size: 20px;
  }

  .scroll-spy li a.is-active {
    width: 30px;
    height: 30px;
    font-size: 20px;
  }
}
