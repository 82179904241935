@import '../../theme/palette.sass';
@import '../../theme/variables.sass';

/* ESTILOS INPUT BUSCADOR */

.contenedor-buscador {
  background-color: $color-white;
  border-radius: 50px;
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.2);
  margin: 0px auto;
  padding: 20px 50px;
  text-align: center;
  width: 65%;

  &-posicionador {
    left: 0px;
    position: relative;
    top: -30px;
  }

  @media screen and (orientation: landscape) {
    padding: 5px 15px;
    width: 70%;
    border-radius: 20px;
  }

  .txt-animado {
    text-align: center !important;
    margin-left: 50px;
    margin-top: 2px;

    @media screen and (min-width: $media-desktop) and (orientation: landscape) {
      margin-left: 50px;
      margin-top: 2px;
    }
  }

  .txt-animado span {
    text-align: center !important;
    position: absolute;
    display: block;
    font-size: 30px;
    color: #a2a2a2 !important;
    z-index: 4;
    font-weight: 100 !important;
    margin-top: 6.5px;

    @media screen and (min-width: $media-desktop) and (orientation: landscape) {
      font-size: 24px;
      margin-top: 5px;
    }
  }

  .txt-animado.mostrar {
    display: block;
  }
  .txt-animado.ocultar {
    display: none;
  }

  .input-buscador {
    z-index: 5;
    font-size: 30px !important;

    @media screen and (min-width: $media-desktop) and (orientation: landscape) {
      font-size: 21px !important;
    }
  }

  .input-buscador .MuiIcon-root {
    font-size: 50px !important;
    color: #ccc;
    left: -20px !important;
    position: relative;

    @media screen and (orientation: landscape) {
      font-size: 32px !important;
      left: 0px !important;
    }
  }

  .input-buscador:hover .MuiIcon-root {
    color: black;
  }
}

.primary-search {
  margin-left: -50px !important;
  width: calc(100% + 100px) !important;
}

/* Scale up the box */
.contenedor-buscador.glow {
  transform: scale(1.1, 1.1);
}

/* Fade in the pseudo-element with the bigger shadow */
.contenedor-buscador.glow::after,
.contenedor-buscador.open::after {
  opacity: 1;
}

.circular-extended {
  width: 34px !important;
  height: 30px;
  display: block;
}

.contenedor-buscador .MuiInput-underline:before,
.contenedor-buscador .MuiInput-underline:after,
.MuiInput-underline:hover:not(.Mui-disabled):before {
  border: none !important;
}

input:focus,
input.form-control:focus,
div:focus {
  outline: none !important;
  outline-width: 0 !important;
  box-shadow: none;
  -moz-box-shadow: none;
  -webkit-box-shadow: none;
}

.wrap-backdrop-buscadoar {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100vh;
  background-color: $color-black;
  z-index: 1100;
  opacity: 0.3;
}

.contenedor-buscador.open {
  z-index: 1201;
}

.underline-bold strong {
  text-decoration: underline;
}

.wrap-resultado-buscador {
  padding-bottom: 40px;

  @media screen and (orientation: landscape) {
    padding-bottom: 20px;
  }

  .gardient-background-line {
    height: 2px;
    background-image: linear-gradient(
      90deg,
      rgba(233, 53, 44, 1) 0%,
      rgba(57, 172, 0, 1) 35%,
      rgba(20, 75, 150, 1) 100%
    );
  }

  .adorno-linea-separador {
    border-top: solid 1px #c4c4c4;
    margin-top: 10px;

    @media screen and (orientation: landscape) {
      margin-top: 3px;
    }
  }
}

.wrap-resultado-buscador.open {
  border: solid 1px gray;
  display: block;
}

.lista-resultados-main-buscador h4 {
  padding: 10px 10px;
  border-bottom: 1px solid #e6e6e6;
  margin: 10px 0px;
  font-family: Roboto;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
  color: #6e6e6e;
}

.lista-resultados-main-buscador ul {
  list-style: none;
  margin: 0px;
  padding: 0px;
}

.lista-resultados-main-buscador ul li {
  text-align: left;
  padding: 5px 0px;
  border-bottom: solid 1px #e6e6e6;

  @media screen and (orientation: landscape) {
    font-size: 16px;
  }
}

.lista-resultados-main-buscador ul li.no-borde {
  border: none;
}

.lista-resultados-main-buscador .underline {
  text-decoration: underline;
}

.lista-resultados-main-buscador ul li img {
  width: 100px;

  @media screen and (orientation: landscape) {
    width: 100px;
    margin-left: 15px;
  }
}

.lista-resultados-main-buscador .titulo-tienda {
  font-weight: bold;
  margin-left: 0px;
  padding: 0px 8px !important;
  float: right;
  text-align: right;
  font-size: 18px;
  padding: 0px;
  text-transform: capitalize;
}

.lista-resultados-main-buscador .route-disabled {
  color: #b1b1b1;
}

.lista-resultados-main-buscador .bajada-tienda,
.lista-resultados-main-buscador .zona-tienda {
  font-size: 13px;

  @media screen and (orientation: landscape) {
    font-size: 12px;
  }
}

.lista-resultados-main-buscador .shop-item {
  height: 60px;
  display: grid;
  cursor: pointer;
  grid-template-rows: 60px;
  align-items: center;
  grid-template-columns: 15% 40% 40%;
  grid-gap: 20px;
}

.lista-resultados-main-buscador .bajada-tienda {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 95%;
  text-align: right;
}

.information-container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-top: 10px;

  .label-piso {
    margin-left: 5px;
    font-size: 14px !important;
    padding: 7px 10px !important;

    @media screen and (orientation: landscape) {
      font-size: 14px !important;
      line-height: 20px !important;
      padding: 5px 8px;
      margin: 2px 5px;
      margin-top: 15px !important;
    }
  }
}
.lista-resultados-main-buscador .informacion-adicional {
  width: auto;
  border-radius: 30px;
  padding: 5px 8px;
  margin: 2px 0px;
}

.lista-resultados-main-buscador .informacion-adicional.fill-red {
  background-color: transparent;
  color: #c12a21;
  font-weight: bold;
  margin-top: 5px;
}
.text-hours {
  color: $color-black;
}

.open-or-open-soon {
  color: $color-black;
  background-color: $color-white;
  border: solid 0px #646464;
}

.lista-resultados-main-buscador .informacion-adicional {
  color: $color-black;
  background-color: $color-white;
  border: solid 0px #646464;
}

.lista-resultados-main-buscador .status-information {
  font-size: 14px;
  padding: 2px;

  @media screen and (orientation: landscape) {
    font-size: 12px;
    padding: 0px;
    float: left;
  }
}

.lista-resultados-main-buscador .no-results {
  background-color: #eeeeee;
  padding: 10px;
  font-weight: bold;
  text-align: left;

  @media screen and (orientation: landscape) {
    font-size: 18px;
  }
}

.circulo-icon {
  border-radius: 100%;
  border: solid 1px #ccc;
  width: 40px;
  height: 40px;
  text-align: center;
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.3);
}

.circulo-icon span {
  margin-top: 5px;
}

.titulo-categoria {
  margin-left: 20px;
  margin-top: 10px;
}

.lista-tiendas {
  max-height: 100%;
  overflow: hidden;
  overflow-x: hidden;
}

.mano-helper {
  position: absolute;
  z-index: 100;
  top: 380px;
  right: 90px;
  opacity: 0;
  display: none;
  -webkit-transition: opacity 0.5s ease-in-out;
  -moz-transition: opacity 0.5s ease-in-out;
  transition: opacity 0.5s ease-in-out;
}

.mano-helper.mostrar {
  opacity: 1;
  display: none;
}

.mano-helper img {
  width: 180px;
  height: auto;
}

.smart-section-container {
  display: grid;
  gap: 20px;
  grid-template-columns: 15% 55% 24%;
  height: 70px;
  width: 100%;
}

.smart-section {
  align-content: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 !important;

  &-icon {
    align-items: center;

    @media screen and (orientation: landscape) {
      .demoIcon {
        font-size: 32px;
      }
    }
  }

  &-adicional-info {
    align-items: flex-end;

    @media screen and (orientation: landscape) {
      .informacion-adicional {
        padding: 18px;
        .informacion-titulo {
          font-size: 14px;
        }
      }
    }
  }

  @media screen and (orientation: landscape) {
    span {
      font-size: 18px;
    }
    font-size: 18px;
  }
}
.escribiendo {
  bottom: 525px;
  left: 50%;
  position: fixed;
  transform: translateX(-50%);

  @media screen and (orientation: landscape) {
    bottom: 5%;
    max-height: 90%;
    overflow-y: scroll;
  }

  @media screen and (min-width: $media-desktop) and (orientation: portrait) {
    bottom: 25%;
    top: 100px;
    max-height: 90%;
    overflow-y: scroll;
  }
}

.search-container {
  position: absolute;
  width: 964px;
  height: 48px;
  left: 30px;
  top: 175px;

  background: #ffffff;
  box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.15);
  border-radius: 20px;

  &-posicionador {
    left: 0px;
    position: relative;
    top: 0px;
  }
}
