@import "../../theme/palette.sass";
@import "../../theme/variables.sass";

/* ESTILOS PANTALLA DESCANSO FIND YOUR CAR */
.fondoPantalla{
    background-image: url(../../assets/images/fondo_home.png);
    background-size: 100% 100%;
    width: 100%;
    height: 100%;
    top: 0px;
    bottom: 0px;
  }
  
  .contenedor-adorno{
    width: 75%;
    text-align: center;
    height: 80%;
    margin-left: auto;
    margin-right: auto;
    padding-top: 10%;
    position: relative;
  }
  
  .cont-img-logo{
    position: absolute;
    width: 100%;
    margin-top: 26.1%;

    @media screen and (orientation: landscape) {
      width: 250px;
      margin-top: 65px;
      top: 100px;
      left: calc(50% - 125px);
    }
  }
  
  .cont-img-logo img{
    width: 53%;
    height: auto;
  }
  
  .cont-img-mascara{
    position: absolute;
    width: 100%;
    height: auto;

    @media screen and (orientation: landscape) {
      width: 250px;
      top: 100px;
      left: calc(50% - 125px);
    }
  }
  
  .cont-img-mascara img{
    width: 100%;
    height: auto;
  }
  
  .seccion-boton{
    width: 100%;
    bottom: 0px;
    position: absolute;
    bottom: 8%;

    @media screen and (orientation: landscape) {
      bottom: calc(50% - 150px);
    }
  }
  
  .seccion-boton button{
    width: 30%;

    @media screen and (orientation: landscape) {
      width: 200px;
    }
  }
  
  .seccion-boton button img{
    width: 100%;
    height: 100%;

    @media screen and (orientation: landscape) {
      width: 100px;
      height: 100px;
    }
  }
  
  .bajada-texto{
    bottom: 5%;
    position: absolute;
    width: 100%;
    font-size: 30px;
    color: $color-gris-light;

    @media screen and (orientation: landscape) {
      bottom: calc(50% - 200px);
    }
  }

@media (max-width: 570px) {
    .bajada-texto{
        font-size: 12px;
    }
}
  
@media (height: 1366px){
    .contenedor-adorno{
      height: 90%;
      width: 70%;
    }
  
    .seccion-boton button{
      width: 25%;
    }
}
  