@import '../../theme/palette.sass';
@import '../../theme/variables.sass';

/* ESTILOS INPUT BUSCADOR DIRECTORIO */
.contenedor-input {
  @media screen and (orientation: landscape) {
    height: 46px;
  }

  @media screen and (min-width: $media-desktop) and (orientation: portrait) {
    height: 87px;
  }

  .buscador-directorio {
    padding-top: 20px;

    .contenedor-buscador {
      @media screen and (orientation: landscape) {
        padding: 5px 15px;
        width: 70%;
      }
    }

    .contenedor-buscador.open {
      @media screen and (min-width: $media-desktop) and (orientation: landscape) {
        border-radius: 20px;
      }
    }

    .escribiendo {
      @media screen and (orientation: landscape) {
        bottom: 5%;
        top: 125px;
        max-height: 90%;
        overflow-y: scroll;
      }

      @media screen and (min-width: $media-desktop) and (orientation: portrait) {
        bottom: 25%;
        top: 238px;
        max-height: 90%;
        overflow-y: scroll;
      }
    }

    .escribiendo-servicios {
      bottom: 525px;
      left: 50%;
      position: fixed;
      transform: translateX(-50%);

      @media screen and (orientation: landscape) {
        bottom: 5%;
        top: 125px;
        max-height: 90%;
        overflow-y: scroll;
      }

      @media screen and (min-width: $media-desktop) and (orientation: portrait) {
        bottom: 25%;
        top: 238px;
        max-height: 90%;
        overflow-y: scroll;
      }
    }

    .lista-resultados-main-buscador {
      color: #000000;
    }

    .lista-resultados-main-buscador .no-results {
      background-color: #eeeeee;
      padding: 20px;
      font-weight: bold;
      text-align: left;

      @media screen and (orientation: landscape) {
        padding: 25px 15px;
        text-align: center;
      }

      @media screen and (min-width: $media-desktop) and (orientation: portrait) {
        padding: 25px 15px;
        text-align: center;
      }

      .text-link {
        margin-top: 10px;
        text-align: center;

        a {
          color: #005ace;
          text-decoration: underline;
        }

        @media screen and (orientation: landscape) {
          margin-top: 15px;
        }

        @media screen and (min-width: $media-desktop) and (orientation: portrait) {
          margin-top: 15px;
        }
      }
    }
  }
}
