.no-result-container {
  margin-left: -15px !important;
  margin-right: -15px !important ;
}
@media (max-width: 380px) {
  .no-result-container {
    margin-left: -18px !important;
    margin-right: -18px !important ;
  }
  .escribiendo {
    width: 81% !important;
  }
}

.dialog-tag-container {
  position: relative;
  .detail-dialog {
    clip-path: polygon(6% 66%, 10% 100%, 40% 100%);
    background-color: #fff;
    width: 106px;
    height: 80px;
    position: absolute;
    top: -75px;
    left: 25px;
  }
  position: relative;
  min-height: 146px;
  background-color: #fff;
  border-radius: 10px;
  margin: 31px 11px 11px 11px;
  .dialog-tag-list {
    width: 100%;
    .title-tag {
      padding: 8px;
      color: #000;
      font-family: Roboto;
      font-style: normal;
      font-weight: 600;
      font-size: 15px;
      line-height: 19px;
    }
    .blue-tag {
      color: blue;
      font-family: Roboto;
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 19px;
    }
    .red-tag {
      color: red !important;
      font-family: Roboto;
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 19px;
    }
  }
}
