.closeQRF{
    position: absolute;
    margin-top: -7%;
    margin-left: calc(100% - 74%);
    width: 3%;
}

.closeQRR{
    position: absolute;
    margin-top: -7%;
    margin-left: calc(100% - 62%);
    width: 3%;
}

.modalQR{

    margin-top: 0%;
    
    .modal-body{
        border-radius: 30px !important;
        width: 55% !important;
        
        .img-error{
            margin-top: 5%;
        }

        .img-error > img{
            width: 60%;
        }

        .QRNull{
            width: 30% !important;
        }
        .text{
            .titleQR{
                font-family: 'Roboto';
                font-style: normal;
                font-weight: 700;
                font-size: 48px;
                line-height: 56px;
                text-align: center !important;

                background: linear-gradient(83.15deg, #C62440 -6.1%, #803460 30.61%, #2F4784 66.73%, #246C74 105.76%);
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                background-clip: text;
                text-fill-color: transparent;
                margin-top: 5%;
            }

            .subTitleQR{
                font-family: 'Roboto';
                font-style: normal;
                font-weight: 400;
                font-size: 36px;
                line-height: 42px;
                text-align: center !important;

                /* PRINCIPALES/Azul 2 Arauco+ */
                color: #154BAE;
                margin-top: 5%;
            }

            .btn-aceptar{
                box-sizing: border-box;
                height: 60px;
                background: #154BAE;
                border-radius: 60px;
                width: 50%;
                color: #FFF;
                border: 0px;
                font-family: 'Roboto';
                font-style: normal;
                font-weight: 700;
                font-size: 26px;
                margin-top: 5%;
            }
        }
    }
}


.modalQRReal{

    margin-top: 50%;

    .QR{
        width: 35% !important;
    }

    .subTitleQR{
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 36px;
        line-height: 42px;
        text-align: center !important;

        /* PRINCIPALES/Azul 2 Arauco+ */
        color: #154BAE;
        margin-top: 5%;
    }
}