
@import "../../theme/variables.sass";

.bg-GRIS{background-color: #707070; color:white !important;}
.bg-GRIS-2{background-color: #4B4B4B;}


.cabecera.bg-GRIS .titulo-cabecera{
    color: white;
    padding: 30px;
    font-size: 30px;
    position: relative;
    z-index: 1110;

    @media screen and (min-width: $media-desktop) and (orientation: landscape) {
        font-size: 34px;
        padding: 38px;
        padding-bottom: 20px;
    }
}

@media (min-width: $media-desktop) and (orientation: portrait) {
    .cabecera.bg-GRIS .titulo-cabecera {
      color: white;
      text-align: center;
      padding: 80px 10px;
      padding-bottom: 30px;
      font-size: 50px;
    }
  }

.cabecera.bg-GRIS .barra a{
    color: white;
}

.cabecera.bg-GRIS .contenido.intermedia{
    color:white;
}

.cabecera.bg-GRIS .contenido.intermedia .demoIcon{
    color: white;
}
