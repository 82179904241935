@import '../../theme/palette.sass';
@import '../../theme/variables.sass';

.cabecera {
  min-height: 300px;
  width: 100%;
  z-index: 10;
  top: 0px;
}

.cabecera-busqueda-estacionamiento {
  position: fixed !important;
}

.cabecera .barra {
  overflow: hidden;
  font-weight: bold;
  font-size: 20px;
  line-height: 0px;

  span {
    font-size: 1.4rem;
    margin-right: 6px !important;
  }
}

.cabecera .barra .txt-idioma {
  line-height: 40px;
}

.cabecera .barra a {
  color: $color-white;
}

.cabecera .barra-titulo h1 {
  font-size: 35px;
  font-weight: normal;
  text-align: center;
  position: absolute;
  width: 100%;
  text-align: center;

  @media screen and (orientation: landscape) {
    font-size: 28px;
    line-height: 33px;
    margin: 30px 0px;
  }
}

.contenedor-mapa {
  margin: 10%;
  margin-top: 350px;

  @media screen and (orientation: landscape) {
    margin: 5% 10%;
    margin-top: 220px !important;
  }
}

.contenedor-mapa img {
  width: 100%;
  height: 100%;
  border-radius: 0px;
}

.contenedor-listas {
  margin: 10%;
  text-align: center;
}

.btn-base-animal {
  display: block;
  padding: 10px 20px;
  overflow: hidden;
  color: $color-white !important;
  min-height: 48px;
}

.btn-base-animal.c-white {
  -webkit-box-shadow: inset 0px 0px 0px 2px $color-black;
  -moz-box-shadow: inset 0px 0px 0px 2px $color-black;
  box-shadow: inset 0px 0px 0px 2px $color-black;
  color: $color-black !important;
}

.numero {
  font-size: 2.125em;
}

.btn-base-animal img {
  border-radius: 0px;
  height: 45px;
}

.izquierda {
  float: left;
}
.derecha {
  float: right;
}
