@import '../../theme/palette.sass';
@import '../../theme/variables.sass';

.error-container{
  text-align: center;

  @media screen and (min-width: $media-desktop) {
    margin-top: 600px;
  }
}

.error {
  color: #E1211B;
  margin-bottom: 0;
}

.notFoundMessageContainer{
  display: inline-grid;
  width: 100%;
  justify-content: center;
}

.notFoundMessage {
  width: 60%;
  font-size: 17px;
  margin: auto;
  font-weight: normal;
}

.notConnectionImage {
  width: 100%;
  margin-top: 60px;
  margin-bottom: 60px;
}