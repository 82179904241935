@import '../../theme/palette.sass';
@import '../../theme/variables.sass';

.c-WHITE {
  background-color: $color-white;
}
.c-BLACK,
.fnd-NEGRO-main {
  background-color: $color-black;
}
.c-CAFE,
.fnd-CAFE-main {
  background-color: $color-brown;
}
.c-AZUL,
.fnd-AZUL-main {
  background-color: $color-blue;
}
.c-ROSADO,
.fnd-ROSADO-main {
  background-color: $color-pink;
}
.c-ROJO,
.fnd-ROJO-main {
  background-color: $color-red;
}
.c-AMARILLO,
.fnd-AMARILLO-main {
  background-color: $color-yellow;
}
.c-NARANJO,
.fnd-NARANJO-main {
  background-color: $color-orange;
}
.c-VERDE,
.fnd-VERDE-main {
  background-color: $color-green;
}
.c-CELESTE,
.fnd-CELESTE-main {
  background-color: $color-ligthBlue;
}
.c-DORADO,
.fnd-DORADO-main {
  background-color: $color-gold;
}
.c-MORADO,
.fnd-MORADO-main {
  background-color: $color-purple;
}
.c-GRIS,
.fnd-GRIS-main {
  background-color: $color-gray;
}
.c-VERDE-2,
.fnd-CALIPSO-main {
  background-color: $color-calipso;
}
.c-disabled {
  background-color: $color-disabled;
}
.fnd-BLANCO-main .cabecera-resultados .barra a {
  color: $color-black;
}
.fnd-BLANCO-main {
  background-color: $color-white;
  color: $color-black;
}

.bg-VERDE-header {
  background-color: $color-green-dark;
}
.bg-MENTA-header {
  background-color: $color-mint-dark;
}
.bg-NEGRO-header {
  background-color: $color-black-dark;
}
.bg-CAFE-header {
  background-color: $color-brown-dark;
}
.bg-AZUL-header {
  background-color: $color-blue-dark;
}
.bg-NARANJO-header {
  background-color: $color-orange-dark;
}
.bg-ROSADO-header {
  background-color: $color-pink-dark;
}
.bg-CELESTE-header {
  background-color: $color-ligthBlue-dark;
}
.bg-ROJO-header {
  background-color: $color-red-dark;
}
.bg-AMARILLO-header {
  background-color: $color-yellow-dark;
}
.bg-DORADO-header {
  background-color: $color-gold-dark;
}
.bg-MORADO-header {
  background-color: $color-purple-dark;
}
.bg-BLANCO-header {
  background-color: $color-white-dark;
}

.bg-VERDE {
  background-color: $color-primary;
  color: $color-white;
}
.bg-VERDE-2 {
  background-color: $color-primary-dark;
  color: $color-white;
}
.bg-MENTA {
  background-color: $color-mint !important;
  color: $color-white !important;
}
.bg-MENTA-2 {
  background-color: $color-mint-dark;
  color: $color-white;
}

.cabecera {
  .barra {
    .btn-volver {
      align-items: center;
      display: flex;
      float: left;
      position: relative;
      text-decoration: none;
      font-style: normal;
      font-weight: bold;
      font-size: 40px;
      line-height: 47px;
      padding: 40px;

      .MuiIcon-root {
        font-size: 45px;
      }

      @media screen and (min-width: $media-desktop) and (orientation: landscape) {
        padding: 2px;
        font-size: 24px;
        line-height: 28px;

        .MuiIcon-root {
          font-size: 28px;
        }
      }
    }
  }
}