@import '../../theme/palette.sass';
@import '../../theme/variables.sass';

.car-finded {
  .titulo {
    margin-top: 100px !important;
    font-size: 50px !important;
  }
  .patente {
    font-size: 40px !important;
  }
  .result-content {
    width: 100% !important;
    padding-top: 0px !important;
    padding-bottom: 15px;

    .title-result {
      margin-bottom: 30px !important;
    }
    .floor-number {
      font-size: 3.5em !important;
    }
    .floor-color {
      font-size: 45px !important;
    }
    .divider {
      width: 70% !important;
      margin: 15px auto !important;
    }
    .title-parking {
      font-size: 40px !important;
    }
    .description-parking {
      font-size: 30px !important;
    }
    .img-parking {
      img {
        width: 20% !important;
        height: auto !important;
        margin-top: 30px !important;
      }
    }
  }

  .btn-volver {
    font-size: 40px;
    span {
      font-size: 2.5rem;
    }
  }

  .car-button-finded {
    width: 80%;
    margin: 40px auto 0px auto;
    padding: 30px 20px;
    border: 1px solid #f9f9f9;
    border-radius: 20px;

    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    line-height: 23px;
    text-align: center;
    color: #f9f9f9;
  }
  .pillar_name {
    font-size: 50px !important;
    position: relative;
    top: 12px;
  }
  .pillar_separator {
    border-left: 1px solid white;
    height: 250px;
    margin: 20px 20px -7px 20px;
    display: inline-block;
  }
}
.container_number {
  display: flex;
  justify-content: center;
  .floor-name {
    font-size: 50px;
    position: relative;
    top: -43px;
  }
}
.reverse_column {
  flex-direction: column;
  margin-top: -10px;
  .floor-code {
    font-size: 2em !important;
  }
}
