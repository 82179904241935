@import '../../theme/palette.sass';
@import '../../theme/variables.sass';

.bg-AZUL {
  background-color: #005ace;
}

.bg-AZUL-2 {
  background-color: #0251b7;
}

.bg-ROJO {
  background-color: #f70c05;
}

.cabecera .contenido.mapa {
  color: $color-white;
  padding: 0px 50px;
  margin-top: 30px;

  @media screen and (min-width: $media-desktop) and (orientation: landscape) {
    height: 200px !important;
    padding: 0px 20px;
    margin-top: 0px;
  }
}

.cabecera.fnd-tienda {
  background-position: bottom right;
  background-size: 100%;
  background-repeat: no-repeat;
  position: initial !important;
}

.cabecera .contenido.mapa .tipo-local {
  font-size: 20px;

  @media screen and (min-width: $media-desktop) and (orientation: landscape) {
    padding-top: 25px;
  }
}

.cabecera .contenido.mapa .nombre-local {
  font-size: 50px;
  font-weight: bold;

  @media screen and (min-width: $media-desktop) and (orientation: landscape) {
    font-size: 30px;
    margin-top: 5px;
  }
}

.cabecera .contenido.mapa .informacion-adicional {
  font-size: 16px;
  float: left !important;
}

.label {
  border: solid 1px $color-white;
  border-radius: 30px;
  padding: 5px 8px;
  margin: 5px;
}

.wrap-imagen-cabecera {
  width: 100%;
  text-align: left;
  margin-top: 20px;

  @media screen and (min-width: $media-desktop) and (orientation: landscape) {
    margin-top: 20px;
  }
}

.wrap-imagen-cabecera {
  img {
    width: 80%;

    @media screen and (min-width: $media-desktop) and (orientation: landscape) {
      width: 55%;
      margin-left: 50px;
    }
  }

  .icon-tienda {
    height: 100%;
    width: 100%;

    .demoIcon {
      color: white;
      font-size: 230px;

      @media screen and (min-width: $media-desktop) and (orientation: landscape) {
        font-size: 180px;
      }
    }
  }
}

.cabeceraResultadoMapa {
  min-height: 260px !important;
}

.seccion-mapa {
  overflow: hidden !important;
  background-color: #fff;
  height: 1515px;
  position: relative;
}

.seccion-mapa-parking {
  overflow: hidden !important;
  background-color: #fff;
  height: 1515px;
  position: relative;
}

.wrap-indicaciones {
  position: absolute;
  z-index: 100;
  right: 30px;
  top: 30px;
}

.footer-controles {
  position: absolute;
  z-index: 100;
  right: 30px;
  bottom: 30px;
}

.cuadro-indicaciones {
  background-color: white;
  padding: 30px 10px;
  box-shadow: 3px 3px 6px rgba(0, 0, 0, 0.3);
  width: 200px;
  text-align: center;
  color: $color-azul-strong;
  font-size: 20px;
  border-radius: 10px;
}

.flecha-indicaciones .material-icons {
  font-size: 100px;
}

.enviar-ruta {
  margin-top: 20px;
}

.btn-enviar-ruta {
  background-color: black !important;
  color: white !important;
  height: 60px;
  border-radius: 90px !important;
  text-transform: none !important;
  box-shadow: 3px 3px 6px rgba(0, 0, 0, 0.3);
}

.btn-enviar-ruta .material-icons {
  margin-left: 5px;
}

.btn-mapa {
  background-color: black !important;
  color: white !important;
  border-radius: 30px !important;
  text-transform: none !important;
  // box-shadow: 3px 3px 6px rgba(0,0,0,0.3);
  box-shadow: none !important;
  width: 120px !important;
  height: 120px !important;
  text-align: center;
}

.btn-mapa .material-icons {
  font-size: 50px;
}

.btn-mapa .icono {
  display: block;
  width: 100%;
  overflow: hidden;
  text-align: center;
}

.btn-mapa .texto {
  display: block;
  width: 100%;
  overflow: hidden;
  text-align: center;
  height: 40px;
  line-height: 15px;
  font-size: 14px;
}

.btn-mapa.MuiButtonBase-root {
  display: initial;
}

.btn-repetir {
  background-color: $color-red-botones !important;
}

.btn-accesible {
  background-color: $color-secondary !important;
}

.centrar-cabecera {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.nombre-local {
  max-height: 174px;
  overflow: hidden;

  @media screen and (min-width: $media-tablet) and (max-width: $media-laptop) {
    overflow: visible;
  }
}

.bg-MINT {
  background-color: #37909a;

  .contenido.mapa {
    .content-select {
      .buscador-lista {
        .contenedor-buscador,
        .contenedor-buscador.open.escribiendo {
          background: #256c74;
        }
      }
    }

    .select-section {
      background: #246c74;

      button {
        background: #1d555b;
      }

      .modal-validation {
        .msg {
          color: #37909a;
        }

        .small-point {
          background-color: #37909a;
        }

        svg {
          path {
            fill: #37909a;
          }
        }
      }
    }
  }

  button {
    background: #1d555b !important;
  }
}
