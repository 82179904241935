@import '../../theme/palette.sass';
@import '../../theme/variables.sass';

.letraVolver {
  font-size: 25px;
  font-weight: bold;
  text-align: left;
}

.titulo{
  font-weight: 700;
  font-size: 45px;
  text-align: center;
}

.imgTicket{
  margin: 30px 0;
  text-align: center;
}

.contenedor-normal{
  margin: 0px 17%;
  margin-top: 60px;
}

.filaTicket{
  display: block;
  margin: 30px 0;
  height: 70px;
}

.numeroTicket{
  display: inline-block;
  width: 10%;
}

.textoTicket{
  font-size: 28px;
  display: inline-block;
  width: 90%;
}

.imgNumero{
  width: 50px;
  height: 50px;
}

.linkTicket{
  display: inline;
  color: #246C74;
  text-decoration: underline #246C74;
  font-weight: 700;
}

.acomodaTitulo{
  min-height: 300px !important;
}

.titulo-cabecera-ticket {
  color: white;
  text-align: center;
  padding: 50px;
  font-size: 34px;
  position: relative;
  z-index: 1110;

  @media screen and (max-width: $media-iphone-8) {
    font-size: 21px;
    line-height: 25px;
    padding: 13px;
    padding-bottom: 12px;
  }

  @media screen and (min-width: $media-iphone-11-i) and (max-width: $media-tablet) {
    font-size: 24px;
    padding: 18px;
  }

  @media screen and (min-width: $media-tablet) and (max-width: $media-laptop-max) {
    font-size: 34px;
    padding: 18px;
  }

  @media screen and (min-width: $media-desktop) and (orientation: landscape) {
    font-size: 34px;
    padding: 18px;
  }
}