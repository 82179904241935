@import '../theme/palette.sass';
@import '../theme/variables.sass';

html,
body {
  overscroll-behavior-x: none;
}

html,
body,
#root,
.generalContainer {
  height: 100%;
}

body {
  font-family: 'Roboto';
  overflow-x: hidden;
  font-size: 16px;
  background-color: $color-gray-light;
  margin: 0px;
  padding: 0px;
}

body::-webkit-scrollbar {
  width: 0px;
}

body::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

body::-webkit-scrollbar-thumb {
  background-color: black;
  outline: 1px solid black;
}

/* // evita seleccion de texto*/
span,
p,
a,
h1,
h2,
h3,
h4,
h5,
h6,
li,
ul,
div,
input {
  -webkit-user-select: none;
  -moz-user-select: none;
  -khtml-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
}

a {
  text-decoration: none;
}
a:visited {
  color: initial;
}

/* //ALINEACION - MARGENES - PADDING - AUX */
.text-center {
  text-align: center;
}
.text-left {
  text-align: left;
}
.text-right {
  text-align: right;
}

.m-r-xs {
  margin-right: 5px;
}
.m-t-xs {
  margin-top: 5px;
}
.m-b-xs {
  margin-bottom: 5px;
}
.m-l-xs {
  margin-left: 5px;
}

.m-r {
  margin-right: 15px !important;
}
.m-t {
  margin-top: 15px !important;
}
.m-b {
  margin-bottom: 15px !important;
}
.m-l {
  margin-left: 15px !important;
}

.m-r-lg {
  margin-right: 30px !important;
}
.m-t-lg {
  margin-top: 30px !important;
}
.m-b-lg {
  margin-bottom: 30px !important;
}
.m-l-lg {
  margin-left: 30px !important;
}

.p-md {
  padding: 30px;
}
.p-l {
  padding-left: 15px;
}
.p-r {
  padding-right: 15px;
}
.p-b {
  padding-bottom: 15px;
}
.p-t {
  padding-top: 15px;
}

.p-lg {
  padding: 30px;
}
.p-t-lg {
  padding-top: 30px;
}
.p-b-lg {
  padding-bottom: 30px;
}
.p-l-lg {
  padding-left: 30px;
}
.p-r-lg {
  padding-right: 30px;
}

.btn-especial {
  border-radius: 100px !important;
  font-size: 20px !important;
  padding: 10px 30px !important;
  text-transform: none !important;
  border-width: 2px !important;
}

.w-400 {
  width: 400px !important;
}
.max-w-400 {
  max-width: 400px !important;
}
.max-w-500 {
  max-width: 500px !important;
}
.max-w-600 {
  max-width: 600px !important;
}

.material-icons {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-feature-settings: 'liga';
  -webkit-font-smoothing: antialiased;
}

/* ESTILOS TECLADO */

.keyboardContainer {
  position: fixed;
  bottom: 0px;
  width: 100%;
  background-color: $color-gray-dark;
  margin-bottom: 0px;

  @media screen and (min-width: 1025px) and (orientation: landscape) {
    display: none;
  }
}

.keyboardContainer.posicion-backdrop {
  z-index: 1200;
}

.simple-keyboard.hg-theme-default {
  background-color: $color-gray-dark;
  border-radius: 0px;
  margin-left: auto;
  margin-right: auto;
  width: 80%;
  margin-top: 2%;
  margin-bottom: 2%;
}

.keyboardContainer .hg-button {
  width: 70px !important;
  height: 70px !important;
  border-radius: 10px !important;
  font-size: 30px;
  background-color: $color-gray-darkest !important;
  color: $color-white !important;
  flex-grow: initial;
  margin-right: 10px !important;
  margin-bottom: 5px !important;
}

.keyboardContainer .hg-button:active {
  background-color: $color-gray-tecla-letra !important;
}

.simple-keyboard .hg-row:first-child .hg-button {
  background-color: $color-gray-tecla-numero !important;
}

.simple-keyboard .hg-row:first-child .hg-button:active {
  background-color: $color-gray-tecla-numero-activo !important;
}

.simple-keyboard .hg-row:nth-child(4) .hg-button:first-child,
.simple-keyboard .hg-row:nth-child(5) .hg-button:first-child {
  background-color: $color-gray-dark !important;
  box-shadow: none;
  border: none;
}

.simple-keyboard .hg-row:nth-child(5) .hg-button:nth-child(2) {
  width: 550px !important;
  background-color: $color-white-light !important;
  color: $color-gray-dark !important;
}

.simple-keyboard .hg-button-bksp span,
.simple-keyboard .hg-button-enter span {
  display: none;
}

.simple-keyboard .hg-row:nth-child(5) .hg-button:nth-child(2) {
  background-color: #4e4e4e !important;
  box-shadow: none !important;
  border: none;
}

.simple-keyboard .hg-button-bksp {
  background-image: url(../assets/images/backspace.png) !important;
  background-position: center !important;
  background-repeat: no-repeat !important;
  background-size: 30% !important;
  width: 150px !important;
  background-color: $color-white-light !important;
  color: $color-gray-dark !important;
}

.simple-keyboard .hg-button-bksp:active {
  background-color: $color-white-dark !important;
}

.simple-keyboard .hg-button-enter {
  background-image: url(../assets/images/enter.png) !important;
  background-position: center !important;
  background-repeat: no-repeat !important;
  background-size: 30% !important;
  background-color: $color-green !important;
  color: $color-white !important;
  width: 150px !important;
}

.simple-keyboard .hg-button-enter:active {
  background-color: $color-green-dark !important;
}

.cabecera.principal {
  height: 450px;
  position: initial;

  @media screen and (orientation: landscape) {
    height: 300px;

    @media screen and (max-width: 1280px) {
      height: 200px;
      min-height: 200px;
    }
  }
}

.posicionar-idioma {
  float: right;
}

.cabecera.principal .contenido {
  text-align: center;
  // TODO: Remover magin-top cuando se agreguen nuevamente los iconos de FYC o servicios
  margin-top: 5vh;

  @media screen and (max-width: $media-iphone-se) {
    h3 {
      font-size: 21px;
      line-height: 25px;
      margin-bottom: 15px;
    }
    margin-top: 50px;
  }

  @media screen and (min-width: $media-iphone-8-i) and (max-width: $media-iphone-8) {
    h3 {
      font-size: 21px;
      line-height: 25px;
      margin-bottom: 15px;
    }
    margin-top: 70px;
  }

  @media screen and (min-width: $media-iphone-11-i) and (max-width: $media-phone) {
    h3 {
      font-size: 24px;
      line-height: 28px;
      margin-bottom: 15px;
    }
    margin-top: 70px;
  }

  @media screen and (min-width: $media-tablet-i) and (max-width: $media-tablet) {
    h3 {
      font-size: 30px;
      line-height: 34px;
      margin-bottom: 20px;
    }
    margin-top: 100px;
  }

  @media screen and (min-width: $media-tablet-pro-i) and (max-width: $media-laptop-max) {
    h3 {
      font-size: 40px;
      line-height: 44px;
      margin-bottom: 30px;
    }
    margin-top: 180px;
  }

  @media screen and (orientation: landscape) {
    h3 {
      font-size: 34px;
      line-height: 38px;
      margin-bottom: 30px;
    }
    margin-top: 70px;

    @media screen and (max-width: 1280px) {
      h3 {
        margin-top: 15px;
      }
      margin-top: 0px;
    }
  }
}

h3 {
  font-size: 55px;
}
h2 {
  font-size: 30px;
}
h4 {
  font-size: 15px !important;
  display: block;
  text-align: left;
  font-weight: normal;
}
