@import '../../theme/variables.sass';

.modal-login {
  width: 60%;
  margin: 100px auto;
  border-radius: 20px;
  background: white;
  padding: 20px;
  font-size: 35px;
  font-family: Roboto, 'Helvetica Neue', Arial, sans-serif;

  @media screen and (max-width: $media-phone) {
    width: 80%;
    margin: 20px auto;
  }

  @media screen and (min-width: $media-tablet-i) and (max-width: $media-tablet-pro) {
    width: 50%;
    margin: 130px auto;
  }

  @media screen and (min-width: $media-tablet-pro-i) and (max-width: $media-laptop) {
    width: 40%;
    margin: 150px auto;
  }

  @media screen and (min-width: $media-desktop) and (orientation: landscape) {
    width: 30%;
    margin: 100px auto;
  }

  .check-efectivo {
    width: 50px;
    height: 30px;
  }

  .header-pay {
    width: 100%;
    text-align: center;
    height: 0px;

    .close-button-x {
      position: relative;
      display: flex;
      top: 0px;
      right: calc(-100% + 20px);
    }
  }

  .TitleConteiner {
    .content {
      text-align: center;
      color: #0251b6 !important;
      h1 {
        font-size: 50px !important;
        margin-bottom: 10px !important;
        background: -webkit-linear-gradient(
          88.04deg,
          #e21e34 1.54%,
          #246c73 98.35%
        ) !important;
        -webkit-background-clip: text !important;
        -webkit-text-fill-color: transparent !important;

        @media screen and (max-width: $media-iphone-se) {
          font-size: 40px;
          line-height: 18px;
          margin-bottom: 8px;
        }
      }
    }
  }

  .div-btn-gen {
    margin-top: 10px;
    margin-left: 20%;

    @media screen and (max-width: $media-iphone-se) {
      margin-top: 0px;
    }

    .btn-gen {
      font-size: 35px;
      line-height: 22px;
      font-weight: bold;
      margin: 0px 0px 20px 0px;
      padding: 20px 130px;
      color: #fff;
      background-color: #0251b6;
      border-color: #0251b6;
      border-radius: 20px;
      text-transform: none;

      @media screen and (max-width: $media-iphone-se) {
        height: 34px;
      }

      @media screen and (min-width: $media-iphone-8-i) and (max-width: $media-iphone-8) {
        font-size: 27px;
        line-height: 23px;
      }

      @media screen and (min-width: $media-iphone-11-i) and (max-width: $media-phone) {
        font-size: 30px;
        line-height: 25px;
      }

      @media screen and (min-width: $media-tablet-i) and (max-width: $media-tablet) {
        font-size: 30px;
        line-height: 25px;
      }

      @media screen and (min-width: $media-tablet-pro-i) and (max-width: $media-laptop) {
        font-size: 30px;
        line-height: 25px;
      }

      &__disabled {
        background-color: rgb(167, 164, 164);
        border-color: grey;
      }
    }
  }
}
