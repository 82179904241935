@import '../../theme/variables.sass';

.Map-container {
  height: 100%;
  width: 100%;
}

.Map-warning {
  width: 400px;
  height: 100px;
  position: fixed;
  margin-top: 20px;
  margin-left: calc(50% - 500px);
  background-color: transparent;
  text-align: center;
  font-size: 40px;
  color: black;
}

.Map-holder {
  position: relative;
  width: 100%;
  height: 100%;
}

.Map-zoom {
  position: absolute;
  background-color: transparent;
  border: none;
  left: 20px;
  z-index: 9;
}

.Map-zoom-in {
  top: 20px;
}

.Map-zoom-out {
  top: 80px;
}

.wrap-combos {
  position: absolute;
  top: 50px;
  left: 50px;
  overflow: hidden;
  width: 300px;
}

.wrap-ddl-combos {
  position: absolute;
  top: 30px;
  left: 50px;
  overflow: hidden;
  width: 300px;
}

.wrap-ddl-combos-to {
  position: absolute;
  top: 90px;
  left: 50px;
  overflow: hidden;
  width: 300px;
}

.wrap-combos select {
  margin-bottom: 20px;
  font-size: 20px;
  width: 100%;
  height: 40px;
  text-transform: capitalize !important;
}

.wrap-path {
  position: absolute;
  bottom: 50px;
  left: 50px;
}

.floor-levels {
  position: fixed;
  z-index: 200;
  display: grid;
  padding: 2em;

  button {
    background-color: white;
    color: #0251b6;
    font-size: 1.1em;
    font-weight: bold;
    font-size: 24px;
    text-transform: capitalize;
    height: 60px;
    width: 120px;
    border-radius: 50px;
    border: 1px solid #0251b6;
    margin-bottom: 7px;
  }

  .MuiButton-label {
    color: #0251b6;
  }

  button.active {
    background-color: #0251b6;
    border: 3px solid #0251b6;
    .MuiButton-label {
      color: white;
    }
  }
  button.active:hover {
    background-color: #0251b6;
    border: 3px solid #0251b6;
    .MuiButton-label {
      color: white;
    }
  }

  .MuiButton-root:hover {
    background-color: white;
  }

  .Mui-disabled {
    border: 1px solid #c2c2c2;
    background-color: white;
    .MuiButton-label {
      color: #c2c2c2;
    }
  }

  .MuiButton-root:hover.Mui-disabled {
    background-color: white;
  }
}

.repeat-route-button {
  position: fixed;
  right: 2em;
  z-index: 200;
  bottom: 2em;

  button {
    background-color: white;
    text-transform: capitalize;
    box-shadow: none;
    color: #0251b6;
    height: 70px;
    min-width: 200px;
    font-size: 24px;
    padding: 0;
    border-radius: 50px;
    border: 1px solid #0251b6;
  }

  .MuiButton-containedPrimary {
    background-color: white;
  }

  .MuiButton-containedPrimary:hover {
    background-color: #0251b6;
    color: white;
  }

  .Mui-disabled {
    border: 1px solid #c2c2c2;
    background-color: white;
    .MuiButton-label {
      color: #c2c2c2;
    }
  }
}

.path-instructions {
  position: absolute;
  z-index: 200;
  right: 0;
  background-color: white;
  padding: 2em;
  font-size: 2em;
  display: grid;
  text-align: center;
  height: 13vh;
  width: 20vw;
  margin: 2em;
  border-radius: 5%;
}

.map-ui-container .zoom-buttons .component div {
  color: #0251b6;
  background-color: white;
  border: 1px solid #0251b6;
  height: 60px;
  width: 55px;
  font-size: 40px;
  text-align: center;
}

.map-ui-container .zoom-buttons .component {
  top: 100% !important;
  left: 0% !important;
  transform: translateX(-0%) translateY(-100%) !important;
  padding: 15px !important;
  box-sizing: border-box !important;

  [data-zoom] {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: auto 0;
  }

  [data-zoom]:hover {
    color: white;
    border-width: 1px;
  }

  div:first-child {
    border-radius: 40px 40px 0px 0px;
    margin-bottom: 3px;
  }

  div:last-child {
    border-radius: 0 0 40px 40px;
    font-size: 60px;
    margin-top: 3px;
    border-top: 1px solid #0251b6;

    :hover {
      font-size: 60px;
    }
  }
}

.map-ui-container .zoom-buttons .component div:hover {
  border-color: #0251b6;
  font-size: 40px;
}

.map-ui-container .zoom-buttons .component div:last-child:hover {
  font-size: 60px;
}

.map-ui-container .component {
  line-height: 45px;
}

.mapBloquer {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: -1;
  background-color: white;
}

#map-holder-control {
  position: fixed;
  width: 100%; //1080px;
  height: 1515px;
  bottom: 0;
  z-index: -10;
}

.modal-error-body {
  .close-button {
    display: flex;
    justify-content: flex-end;
    margin: -25px -10px 0px 0px;
  }

  .img-error span {
    font-size: 4em;
    color: red;
  }

  .button-modal {
    background-color: green;
    font-size: 1em;
    margin-top: 1em;
    border-radius: 10%;
  }
}

.loading-map-wrapper {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 5000;
  background-color: rgba(255, 255, 255, 0.8);
  display: flex;
  align-items: center;

  .loading-icon {
    margin: auto;
    display: grid;
    grid-gap: 1em;
    font-size: 3em;
    text-align: center;

    div {
      width: 100px !important;
      height: 100px !important;
      margin: auto;
    }
  }
}
