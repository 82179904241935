@import '../../theme/palette.sass';
@import '../../theme/variables.sass';

/* estilos busqueda por estacionamiento */

.cabecera-resultados {
  width: 100%;
}

.cabecera-resultados .barra {
  padding: 20px;
  overflow: hidden;
}

.barra-resultado {
  padding: 40px !important;
}

.cabecera-resultados .barra {
  padding: 20px;
  overflow: hidden;
  font-weight: bold;
  height: 30px;
  font-size: 20px !important;
  line-height: 20px !important;
  @media screen and (max-width: $media-iphone-8) {
    padding: 7px 20px;
    height: 22px;
    line-height: 21px;
    font-size: 18px;
  }
  @media screen and (min-width: $media-iphone-11-i) and (max-width: $media-laptop-max) {
    padding: 13px 20px;
    height: 22px;
    line-height: 21px;
    font-size: 18px;

    .btn-volver {
      margin-top: 0px;
    }
  }
  @media screen and (orientation: landscape) {
    padding: 13px 20px;
    height: 22px;
    line-height: 21px;
    font-size: 18px;

    .btn-volver {
      margin-top: 0px;
    }
  }
}

.cabecera-resultados .barra a {
  color: $color-white;
  font-size: 20px;
}

.cabecera-resultados .barra .txt-idioma {
  line-height: 40px;
}

.fnd-full {
  width: 100%;
  min-height: 100vh;

  .btn-container {
    width: 100%;
    text-align: center;

    .btn-parking-map {
      background: #ffffff;
      border-radius: 20px;
      margin: 20px auto;
      width: 86%;
      text-transform: none;
      font-style: normal;
      font-weight: bold;
      font-size: 18px;
      line-height: 22px;
      text-align: center;
      color: #000000;
      padding: 10px 20px;

      @media screen and (min-width: $media-iphone-8-i) and (max-width: $media-iphone-8) {
        font-size: 21px;
        line-height: 25px;
        margin: 25px auto;
        width: 82%;
      }

      @media screen and (min-width: $media-iphone-11-i) and (max-width: $media-phone) {
        font-size: 21px;
        line-height: 25px;
        margin: 30px auto;
        width: 80%;
      }

      @media screen and (min-width: $media-tablet-i) and (max-width: $media-tablet) {
        font-size: 25px;
        line-height: 29px;
        margin: 40px auto;
        width: 70%;
        padding: 12px 30px;
        border-radius: 30px;
      }

      @media screen and (min-width: $media-tablet-pro-i) and (max-width: $media-laptop-max) {
        font-size: 30px;
        line-height: 34px;
        margin: 40px auto;
        width: 70%;
        padding: 13px 30px;
        border-radius: 30px;
      }

      @media screen and (orientation: landscape) {
        font-size: 23px;
        line-height: 27px;
        margin: 40px auto;
        width: 40%;
        padding: 12px 30px;
        border-radius: 30px;
      }

      &__BLANCO {
        color: #000000;
      }
      &__AZUL {
        color: #013171;
      }
      &__ROSADO {
        color: #eb008b;
      }
      &__CAFE {
        color: #976f53;
      }
      &__ROJO {
        color: #c12a21;
      }
      &__AMARILLO {
        color: #fbc527;
      }
      &__NARANJO {
        color: #ff5c00;
      }
      &__VERDE {
        color: #009a35;
      }
      &__CELESTE {
        color: #009ee5;
      }
      &__DORADO {
        color: #b7982a;
      }
      &__MORADO {
        color: #860085;
      }
    }
  }
}

.info-piso {
  padding-top: 15%;
  @media screen and (max-width: $media-iphone-se) {
    padding-top: 5%;
  }
  @media screen and (min-width: $media-iphone-8-i) and (max-width: $media-iphone-8) {
    padding-top: 0%;
  }
  @media screen and (min-width: $media-iphone-11-i) and (max-width: $media-iphone-11) {
    padding-top: 0%;
  }
  @media screen and (min-width: $media-tablet-i) and (max-width: $media-tablet) {
    padding-top: 7%;
  }
  @media screen and (min-width: $media-tablet-pro-i) and (max-width: $media-laptop-max) {
    padding-top: 8%;
  }
  @media screen and (orientation: landscape) {
    padding-top: 5%;
  }
}

.info-piso,
.figura-piso {
  margin: 0% 0%;
  color: $color-white;
}

.fnd-BLANCO-main .info-piso {
  color: $color-black;
}

.info-piso .numero-piso {
  font-size: 10em;
  font-weight: bold;
  text-align: center;
  line-height: 85%;

  @media screen and (max-width: $media-iphone-se) {
    font-size: 90px;
  }
  @media screen and (min-width: $media-iphone-8-i) and (max-width: $media-iphone-8) {
    font-size: 70px;
  }
  @media screen and (min-width: $media-iphone-11-i) and (max-width: $media-iphone-11) {
    font-size: 100px;
  }
  @media screen and (min-width: $media-tablet-i) and (max-width: $media-tablet) {
    font-size: 180px;
  }
  @media screen and (min-width: $media-tablet-pro-i) and (max-width: $media-laptop-max) {
    font-size: 280px;
  }
  @media screen and (orientation: landscape) {
    font-size: 160px;
  }
}

.info-piso .color-piso {
  font-size: 3em;
  font-weight: bold;
  text-align: center;
  text-transform: uppercase;

  @media screen and (max-width: $media-iphone-se) {
    font-size: 40px;
  }
  @media screen and (min-width: $media-iphone-8-i) and (max-width: $media-iphone-8) {
    font-size: 35px;
  }
  @media screen and (min-width: $media-iphone-11-i) and (max-width: $media-iphone-11) {
    font-size: 40px;
  }
  @media screen and (min-width: $media-tablet-i) and (max-width: $media-laptop-max) {
    font-size: 75px;
  }
  @media screen and (orientation: landscape) {
    font-size: 45px;
  }
}

.info-piso .adorno {
  border-top: solid 10px $color-white;
  width: 80%;
  text-align: center;
  margin-bottom: 20px;
  margin-left: auto;
  margin-right: auto;
}

.info-piso {
  .cajero {
    border: solid 5px #ffffff !important;
    color: #ffffff !important;
    width: 90% !important;
    margin-left: 5% !important;
    margin-top: 20% !important;
    margin-bottom: 5% !important;
  }

  .cajero-black {
    border: solid 5px #000000 !important;
    color: #000000 !important;
    width: 90% !important;
    margin-left: 5% !important;
    margin-top: 20% !important;
    margin-bottom: 5% !important;
  }
}

.checkBoxCustom {
  top: 0;
  left: 0;
  margin-bottom: 10%;
  margin-left: 25%;
}

.checkBoxCustom + span {
  color: #ffffff !important;
}

.checkBoxCustomBlack + span {
  color: #000000 !important;
}

.fnd-BLANCO-main .info-piso .adorno {
  border-top: solid 3px $color-black;
}

.info-piso .titulo-estacionamiento {
  font-size: 4.1em;
  font-weight: normal;
  text-align: center;
  text-transform: uppercase;
  line-height: 90%;

  @media screen and (max-width: $media-iphone-se) {
    font-size: 20px;
  }
  @media screen and (min-width: $media-iphone-8-i) and (max-width: $media-iphone-8) {
    font-size: 24px;
  }
  @media screen and (min-width: $media-iphone-11-i) and (max-width: $media-iphone-11) {
    font-size: 28px;
  }
  @media screen and (min-width: '428px') and (max-width: '926px') {
    font-size: 28px;
  }
  @media screen and (min-width: $media-tablet-i) and (max-width: $media-tablet) {
    font-size: 46px;
  }
  @media screen and (min-width: $media-tablet-pro-i) and (max-width: $media-laptop-max) {
    font-size: 50px;
  }
  @media screen and (orientation: landscape) {
    font-size: 20px;
  }
}

.info-piso .zona-estacionamiento {
  font-size: 4.5em;
  font-weight: bold;
  text-align: center;
  text-transform: uppercase;
  line-height: 100%;

  @media screen and (max-width: $media-iphone-se) {
    font-size: 22px;
  }
  @media screen and (min-width: $media-iphone-8-i) and (max-width: $media-iphone-8) {
    font-size: 26px;
  }
  @media screen and (min-width: $media-iphone-11-i) and (max-width: $media-iphone-11) {
    font-size: 30px;
  }
  @media screen and (min-width: '428px') and (max-width: '926px') {
    font-size: 30px;
  }
  @media screen and (min-width: $media-tablet-i) and (max-width: $media-tablet) {
    font-size: 50px;
  }
  @media screen and (min-width: $media-tablet-pro-i) and (max-width: $media-laptop-max) {
    font-size: 56px;
  }
  @media screen and (orientation: landscape) {
    font-size: 22px;
  }
}

.figura-piso {
  text-align: center;
  padding-top: 10%;
  overflow: hidden;

  @media screen and (orientation: landscape) {
    padding-top: 5%;
  }

  @media screen and (min-width: $media-iphone-8-i) and (max-width: $media-iphone-8) {
    padding-top: 2%;
  }

  @media screen and (min-width: $media-iphone-11-i) and (max-width: $media-iphone-11) {
    padding-top: 2%;
  }
}

.figura-piso img {
  height: 30%;
  width: auto;

  @media screen and (max-width: $media-iphone-se) {
    height: auto;
    width: 32%;
  }
  @media screen and (min-width: $media-iphone-8-i) and (max-width: $media-iphone-8) {
    height: auto;
    width: 33%;
  }
  @media screen and (min-width: $media-iphone-11-i) and (max-width: $media-iphone-11) {
    height: auto;
    width: 30%;
  }

  @media screen and (min-width: '428px') and (max-width: '926px') {
    height: auto;
    width: 34%;
  }
  @media screen and (min-width: $media-tablet-i) and (max-width: $media-tablet) {
    height: auto;
    width: 30%;
  }
  @media screen and (min-width: $media-tablet-pro-i) and (max-width: $media-laptop-max) {
    height: auto;
    width: 29%;
  }
  @media screen and (orientation: landscape) {
    height: auto;
    width: 12%;
  }
}
.row {
  display: flex;
  justify-content: center;
}
.col-8 {
  width: 70%;
}
.buttonGo {
  text-align: center;
  background-color: #fff;
  border-radius: 20px;
  height: 60px;
  margin-top: 50px;
  padding: 16px;
  div {
    font-family: 'Roboto';
    font-weight: 700;
    font-size: 30px;
    line-height: 55px;
  }
}
