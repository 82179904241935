.lengua{
    position: absolute;
    width: 516px;
    height: 125px;
    margin-left: calc(100% - 45%);
    top: 1%;
    background: #DD1F36;
    border-radius: 80px 0px 0px 80px;
    transform: rotate(0);
    z-index: 10000 !important;

    img{
        margin-left: calc(100% - 90%);
        margin-top: 3%;
    }

    span{
        text-align: center !important;
        margin-left: calc(100% - 95%);
        position: absolute;
        margin-top: 7%;
        font-size: 32px;
        line-height: 43px;
        color: #FFFFFF;
    }
}