@font-face {
  font-family: 'parque_arauco_v2';
  src: url('../assets/fonts/FontArauco-v2.0/fonts/parquearauco.eot');
  src: url('../assets/fonts/FontArauco-v2.0/fonts/parquearauco.eot')
      format('embeddedOpentype'),
    url('../assets/fonts/FontArauco-v2.0/fonts/parquearauco.woff') format('woff'),
    url('../assets/fonts/FontArauco-v2.0/fonts/parquearauco.ttf')
      format('truetype'),
    url('../assets/fonts/FontArauco-v2.0/fonts/parquearauco.svg') format('svg');
  font-weight: normal;
  font-style: normal;
}

.demoIcon {
  display: inline-block;
  font-family: 'parque_arauco_v2';
  font-size: 2.5em;
  font-style: normal;
  font-variant: normal;
  line-height: 1em;
  margin-top: 1px;
  mozosx-fontsmoothing: grayscale;
  speak: none;
  text-align: center;
  text-decoration: inherit;
  text-transform: none;
  webkit-fontsmoothing: antialiased;
  width: 1em;
}

.demoIcon i {
  font-style: normal;
}
.icon-Accesorios_y_bijouterie:before {
  content: '\e900';
}
.icon-Agencia_de_viajes:before {
  content: '\e901';
}
.icon-Articulos_religiosos:before {
  content: '\e902';
}
.icon-Automotriz:before {
  content: '\e903';
}
.icon-Bancos:before {
  content: '\e904';
}
.icon-Bazar_gourmet:before {
  content: '\e905';
}
.icon-Bebes_y_embarazadas:before {
  content: '\e906';
}
.icon-Belleza:before {
  content: '\e907';
}
.icon-Boulevard:before {
  content: '\e908';
}
.icon-Calzado_femenino:before {
  content: '\e909';
}
.icon-Calzado_infantil:before {
  content: '\e90a';
}
.icon-Calzado_masculino:before {
  content: '\e90b';
}
.icon-Carteras:before {
  content: '\e90c';
}
.icon-Chocolate:before {
  content: '\e90d';
}
.icon-Comida_americana:before {
  content: '\e90e';
}
.icon-Comida_asiatica:before {
  content: '\e90f';
}
.icon-Comida_chilena:before {
  content: '\e910';
}
.icon-Comida_internacional:before {
  content: '\e911';
}
.icon-Comida_italiana:before {
  content: '\e912';
}
.icon-Comida_peruana:before {
  content: '\e913';
}
.icon-Computacion_y_tecnologia:before {
  content: '\e914';
}
.icon-Deporte_y_outdoors:before {
  content: '\e915';
}
.icon-Distrito_de_lujo:before {
  content: '\e916';
}
.icon-Entretencion:before {
  content: '\e917';
}
.icon-Farmacias:before {
  content: '\e918';
}
.icon-Fotografia:before {
  content: '\e919';
}
.icon-Grandes_tiendas:before {
  content: '\e91a';
}
.icon-Heladeria_cafeteria:before {
  content: '\e91b';
}
.icon-Hogar_deco:before {
  content: '\e91c';
}
.icon-Joyeria:before {
  content: '\e91d';
}
.icon-Lenceria:before {
  content: '\e91e';
}
.icon-Libros_libreria:before {
  content: '\e91f';
}
.icon-Manualidades:before {
  content: '\e920';
}
.icon-Mascotas:before {
  content: '\e921';
}
.icon-Musica_e_instrumentos:before {
  content: '\e922';
}
.icon-Optica:before {
  content: '\e923';
}
.icon-Panaderia_heladeria:before {
  content: '\e924';
}
.icon-Patio_de_comida:before {
  content: '\e925';
}
.icon-Piso_diseno:before {
  content: '\e926';
}
.icon-Restaurantes:before {
  content: '\e927';
}
.icon-Salud:before {
  content: '\e928';
}
.icon-Servicios:before {
  content: '\e929';
}
.icon-Souvenirs:before {
  content: '\e92a';
}
.icon-Telefonia_internet_television:before {
  content: '\e92b';
}
.icon-Tienda_comida_interior:before {
  content: '\e92c';
}
.icon-Tiendas_especializadas:before {
  content: '\e92d';
}
.icon-Turismo:before {
  content: '\e92e';
}
.icon-Vestuario_femenino:before {
  content: '\e92f';
}
.icon-Vestuario_infantil:before {
  content: '\e930';
}
.icon-Vestuario_juvenil:before {
  content: '\e931';
}
.icon-Vestuario_masculino:before {
  content: '\e932';
}
.icon-Vinos:before {
  content: '\e933';
}
.icon-Agencias_de_turismo:before {
  content: '\e934';
}
.icon-Ascensores:before {
  content: '\e935';
}
.icon-Bano_de_hombres:before {
  content: '\e936';
}
.icon-Bano_de_mujeres:before {
  content: '\e937';
}
.icon-Bano_familiar:before {
  content: '\e938';
}
.icon-Bicicleteros:before {
  content: '\e939';
}
.icon-Cajeros:before {
  content: '\e93a';
}
.icon-Cargadores_de_celular:before {
  content: '\e93b';
}
.icon-Casas_de_cambio:before {
  content: '\e93c';
}
.icon-Click_and_collect:before {
  content: '\e93d';
}
.icon-Coches:before {
  content: '\e93e';
}
.icon-Drop_off_and_pick_up_points:before {
  content: '\e93f';
}
.icon-Electrolineras:before {
  content: '\e940';
}
.icon-Lavado_de_autos:before {
  content: '\e941';
}
.icon-Lockers:before {
  content: '\e942';
}
.icon-Maquinas_dispensadoras:before {
  content: '\e943';
}
.icon-Mudadores_de_bebes:before {
  content: '\e944';
}
.icon-Personal_shopper:before {
  content: '\e945';
}
.icon-Primeros_auxilios:before {
  content: '\e946';
}
.icon-Sala_de_lactancia:before {
  content: '\e947';
}
.icon-Salidas:before {
  content: '\e948';
}
.icon-Servicio_al_cliente:before {
  content: '\e949';
}
.icon-Silla_de_ruedas:before {
  content: '\e94a';
}
.icon-Taxis_privados:before {
  content: '\e94b';
}
